import { useEffect, StrictMode } from 'react';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { useAtom } from 'jotai';
import { Spin } from 'antd';

import { userAtom } from './atoms';

export default function AnonymousAuthWrapper({ children, isLoaded }) {
  const auth = getAuth();
  const [, setUser] = useAtom(userAtom);

  useEffect(() => {
    let unsubscribe;
    if (auth) {
      unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user);
        } else {
          signInAnonymously(auth);
        }
      });
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [auth, setUser]);

  return isLoaded ? (
    <StrictMode>{children}</StrictMode>
  ) : (
    <div className="loading">
      <Spin size="large" />
    </div>
  );
}
