import { Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';

import Home from './Home';
import Products from './Products/Products';
import PGLessons from './Lessons/PGLessons';
import HGLessons from './Lessons/HGLessons';
import Payment from './Lessons/Payment';
import BlogItem from './Blog/BlogItem';
import Blog from './Blog/Blog';
import Calendar from './Calendar';
import Weather from './Weather';
import Search from './Search';
import Admin from './Admin/Admin';
import AnonymousAuthWrapper from './AnonymousAuthWrapper';

import './Content.css';

function Content({ db, storage, isLoaded }) {
  const withAnonymousAuth = (element) => <AnonymousAuthWrapper isLoaded={isLoaded}>{element}</AnonymousAuthWrapper>;

  return (
    <Layout.Content>
      <Routes>
        <Route path="/products/sale" element={withAnonymousAuth(<Products db={db} onlySaleItems />)} />
        <Route path="/products/:category/:subCategory/:product" element={withAnonymousAuth(<Products db={db} />)} />
        <Route path="/products/:category/:subCategory" element={withAnonymousAuth(<Products db={db} />)} />
        <Route path="/products/:category" element={withAnonymousAuth(<Products db={db} />)} />
        <Route path="/collections/:category" element={withAnonymousAuth(<Products db={db} />)} />
        <Route path="/products" element={withAnonymousAuth(<Products db={db} />)} />
        <Route path="/pages/lessons" element={withAnonymousAuth(<PGLessons db={db} />)} />
        <Route path="/pg-lessons" element={withAnonymousAuth(<PGLessons db={db} />)} />
        <Route path="/pages/hang-gliding" element={withAnonymousAuth(<HGLessons db={db} />)} />
        <Route path="/hg-lessons" element={withAnonymousAuth(<HGLessons db={db} />)} />
        <Route path="/deposit" element={withAnonymousAuth(<Payment db={db} />)} />
        <Route path="/deposit/:code" element={withAnonymousAuth(<Payment db={db} />)} />
        <Route path="/pg-lessons/deposit" element={withAnonymousAuth(<Payment type="pg" db={db} />)} />
        <Route path="/pg-lessons/deposit/:code" element={withAnonymousAuth(<Payment type="pg" db={db} />)} />
        <Route path="/hg-lessons/deposit" element={withAnonymousAuth(<Payment type="hg" db={db} />)} />
        <Route path="/hg-lessons/deposit/:code" element={withAnonymousAuth(<Payment type="hg" db={db} />)} />
        <Route path="/blog/:id" element={withAnonymousAuth(<BlogItem db={db} />)} />
        <Route path="/blog" element={withAnonymousAuth(<Blog db={db} />)} />
        <Route path="/calendar" element={withAnonymousAuth(<Calendar db={db} />)} />
        <Route path="/conditions" element={withAnonymousAuth(<Weather db={db} />)} />
        <Route path="/weather" element={withAnonymousAuth(<Weather db={db} />)} />
        <Route path="/search" element={withAnonymousAuth(<Search db={db} />)} />
        <Route path="/admin" element={<Admin db={db} storage={storage} />} />
        <Route path="/" element={withAnonymousAuth(<Home db={db} />)} />
        <Route path="*" element={withAnonymousAuth(<Home db={db} />)} />
      </Routes>
    </Layout.Content>
  );
}

export default Content;
