import { useState, useEffect, useCallback } from 'react';
import { collection, onSnapshot, query, orderBy, limit } from 'firebase/firestore';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Grid, Table, Switch, Menu } from 'antd';
import dayjs from 'dayjs';
import { FaRegClock, FaCamera, FaCanadianMapleLeaf } from 'react-icons/fa';
import { CgArrowLongDown } from 'react-icons/cg';
import { VscGraphLine } from 'react-icons/vsc';
import { TbBrandWindy } from 'react-icons/tb';
import { GiWindsock } from 'react-icons/gi';

import './Weather.css';

const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const getHighestWind = (data = []) => {
  let highest;
  data.forEach((datum) => {
    if (!highest || datum?.gust > highest.gust) highest = datum;
  });
  return highest;
};

const getEvenHourTicks = (minTimeEpoch, maxTimeEpoch, interval = 1) => {
  const minDate = dayjs.unix(minTimeEpoch);
  const maxDate = dayjs.unix(maxTimeEpoch);
  let middleTicks = [];
  let currentTick = maxDate;
  while (currentTick.isAfter(minDate)) {
    currentTick = currentTick.subtract(interval, 'hour');
    middleTicks = [currentTick.unix(), ...middleTicks];
  }
  if (currentTick === maxDate) return [];
  return [...middleTicks, maxTimeEpoch];
};

const getEvenSpeedTicks = (minSpeed, maxSpeed, interval = 5) => {
  const middleTicks = [];
  let currentTick = minSpeed;
  while (currentTick < maxSpeed) {
    currentTick += interval;
    middleTicks.push(currentTick);
  }
  return [minSpeed, ...middleTicks];
};

function Weather({ db }) {
  const screen = Grid.useBreakpoint();
  const [menuTab, setMenuTab] = useState('0');
  const [tempestDataW, setTempestDataW] = useState([]);
  const [tempestDataE, setTempestDataE] = useState([]);
  const [tableShowMore, setTableShowMore] = useState(true);
  const [isEast, setIsEast] = useState(false);
  const tempestData = isEast ? tempestDataE : tempestDataW;

  const numToFetch = 181; // 6 hours of 2 min intervals + 1
  const fetchTempestW = useCallback(() => {
    const ref = collection(db, `tempestData/336430/data`); // west
    const q = query(ref, orderBy('timestamp', 'desc'), limit(numToFetch));
    return onSnapshot(q, (snapshot) => {
      const data = [];
      snapshot.forEach((doc) => data.push(doc.data()));
      if (data.length) setTempestDataW(data);
    });
  }, [db, setTempestDataW]);

  const fetchTempestE = useCallback(() => {
    const ref = collection(db, `tempestData/336431/data`); // east
    const q = query(ref, orderBy('timestamp', 'desc'), limit(numToFetch));
    return onSnapshot(q, (snapshot) => {
      const data = [];
      snapshot.forEach((doc) => data.push(doc.data()));
      if (data.length) setTempestDataE(data);
    });
  }, [db, setTempestDataE]);

  useEffect(() => {
    const unsubW = fetchTempestW();
    const unsubE = fetchTempestE();
    return () => {
      if (unsubW) unsubW();
      if (unsubE) unsubE();
    };
  }, [fetchTempestW, fetchTempestE]);

  const earliestTimestamp =
    tempestData?.length &&
    dayjs
      .unix(tempestData[0].timestamp)
      .subtract(screen.md ? 6 : 4, 'hour')
      .unix();
  const mappedData = tempestData
    .filter((data) => data.timestamp >= earliestTimestamp)
    .map((data) => {
      if (!data?.obs) return null;
      return {
        ...data.obs,
        timestamp: data.timestamp,
        time: dayjs.unix(data.timestamp).format(tableShowMore ? 'H:mm' : 'h:mma'),
        lull: data.obs.lull,
        avg: data.obs.avg,
        gust: data.obs.gust,
        temp: data.obs.temp,
        rh: data.obs.rh,
        pressure: data.obs.pressure,
        precip: data.obs.precip,
        lux: data.obs.lux,
      };
    });
  const roundedData = mappedData.map((data) => {
    return {
      ...data,
      lull: round(data.lull),
      avg: round(data.avg),
      gust: round(data.gust),
      temp: round(data.temp, 1),
      rh: round(data.rh, 1),
      pressure: round(data.pressure, 1),
      precip: round(data.precip, 1),
    };
  });
  const tableData = tableShowMore ? roundedData : roundedData.slice(0, screen.md ? 10 : 10);
  const renderSpeed = (kph) => (
    <span
      style={{
        color:
          kph >= 50
            ? 'darkmagenta'
            : kph >= 35
            ? 'darkred'
            : kph >= 30
            ? 'darkgoldenrod'
            : kph >= 15
            ? 'forestgreen'
            : undefined, //'darkgreen',
        fontWeight: kph >= 50 ? 'bold' : kph >= 15 ? '500' : undefined,
      }}
    >
      {kph}
    </span>
  );
  const tableColumns = [
    { title: <FaRegClock />, dataIndex: 'time', key: 'time' },
    {
      title: <GiWindsock />,
      dataIndex: 'dir',
      key: 'dir',
      render: (dir) => (
        <CgArrowLongDown
          style={{
            transform: `rotate(${dir}deg) scale(1.5)`,
            color:
              dir < 100 || dir > 260
                ? 'darkred'
                : dir < 120 || dir > 240
                ? 'darkgoldenrod'
                : dir < 140 || dir > 220
                ? undefined
                : dir > 170 && dir < 190
                ? 'forestgreen'
                : 'darkgreen',
          }}
        />
      ),
    },
    {
      title: 'Lull (kph)',
      dataIndex: 'lull',
      key: 'lull',
      render: renderSpeed,
    },
    { title: 'Avg (kph)', dataIndex: 'avg', key: 'avg', render: renderSpeed },
    { title: 'Gust (kph)', dataIndex: 'gust', key: 'gust', render: renderSpeed },
    { title: 'Temp (C)', dataIndex: 'temp', key: 'temp' },
  ];
  if (screen.lg) {
    tableColumns.push({ title: 'RH (%)', dataIndex: 'rh', key: 'rh' });
  }
  if (screen.xl) {
    tableColumns.push({ title: 'Press (mb)', dataIndex: 'pressure', key: 'pressure' });
  }
  if (screen.xxl) {
    tableColumns.push({
      title: 'Precip (mm)',
      dataIndex: 'precip',
      key: 'precip',
      render: (mm) => (
        <span
          style={{
            color: mm >= 1 ? 'darkblue' : undefined,
            fontWeight: mm >= 1 ? 'bold' : mm >= 0.1 ? '500' : undefined,
          }}
        >
          {mm}
        </span>
      ),
    });
  }
  const graphData = screen.md ? mappedData : mappedData.slice(0, 121); // 4 hours of 2 min intervals
  const highestGust = getHighestWind(graphData)?.gust;
  const xTicks = getEvenHourTicks(graphData[graphData.length - 1]?.timestamp, graphData[0]?.timestamp);
  const yTicks = getEvenSpeedTicks(0, highestGust ? highestGust + 5 : 40);

  const getFlyableDesc = () => {
    const getObsScore = (obs) => {
      if (obs.temp < 0) return [20, 'Freezing', 'cornflowerblue'];
      if (obs.precip >= 2) return [20, 'Raining', 'cornflowerblue'];
      if (obs.dir < 100 || obs.dir > 260) return [20, 'Bad direction', 'darkred', 'white'];
      if (obs.gust > 50) return [10, 'Deadly', 'black', 'white'];
      if (obs.gust > 35) return [30, 'Too strong', 'darkred', 'white'];
      if (obs.precip >= 1) return [50, 'Probably raining', 'cornflowerblue'];
      if (obs.gust > 30) return [50, 'Almost too strong', 'gold'];
      if (obs.dir < 110 || obs.dir > 250) return [50, 'Pretty cross', 'gold'];
      if (obs.gust - obs.lull > 15) return [60, "Maybe but it's gusty", 'gold'];
      if (obs.dir < 120 || obs.dir > 240) return [60, "Maybe but it's a bit cross", 'gold'];
      if (obs.avg < 5 || obs.gust < 10) return [70, 'Probably but very light', 'yellowgreen'];
      if (obs.avg < 10 || obs.gust < 15) return [80, "Probably but it's light", 'yellowgreen'];
      if (obs.dir < 130 || obs.dir > 230) return [80, 'Probably but a little cross', 'yellowgreen'];
      if (obs.dir > 175 && obs.dir < 185) return [100, 'Straight in', 'forestgreen', 'white'];
      if (obs.dir > 170 && obs.dir < 190) return [95, 'Pretty straight in', 'forestgreen', 'white'];
      return [90, 'Probably', 'forestgreen', 'white'];
    };

    const lastObs = mappedData?.length && mappedData[0];
    if (!lastObs?.timestamp) return [0, 'No readings', 'grey', 'white'];
    if (dayjs.unix(lastObs.timestamp).add(5, 'minute').isBefore(dayjs()))
      return [0, 'No current reading', 'grey', 'white'];
    if (lastObs.lux < 50) return [10, 'Dark', 'black', 'white'];

    const lastObsScore = getObsScore(lastObs);
    if (lastObsScore) {
      if (lastObsScore[0] < 50) return lastObsScore;
      const prevObs = mappedData?.length && mappedData.slice(1, 6);
      const prevObsFail = prevObs?.map(getObsScore).find((score) => score?.length && score[0] < 50);
      if (prevObsFail) lastObsScore[1] = `Maybe but recently ${prevObsFail[1].toLowerCase()}`;
      return lastObsScore;
    }

    return [0, 'No current reading', 'grey', 'white'];
  };

  const camera = (
    <div className="camera-container">
      <iframe src="https://rtsp.me/embed/83htGGYQ/" frameBorder="0" allowFullScreen></iframe>
      <p align="right" style={{ marginRight: '1em' }}>
        powered by{' '}
        <a href="https://rtsp.me" title="RTSP.ME - Free website RTSP video steaming service" target="_blank">
          rtsp.me
        </a>
      </p>
    </div>
  );

  const stationSwitch = (
    <Switch
      className="tempest-switch"
      unCheckedChildren="West station"
      checkedChildren="East station"
      onChange={(checked) => setIsEast(checked)}
      checked={isEast}
    />
  );

  // const tempest = <iframe src="https://tempestwx.com/station/137430/grid" frameborder="0"></iframe>;
  // const tempest = <iframe src="https://www.wunderground.com/weather/IROCKY122" frameborder="0"></iframe>;
  const tempestChart = (
    <div
      style={{
        width: '100%',
        height: '100%',
        margin: '0.25em 0.5em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {!screen.md && stationSwitch}
      <div style={{ width: '100%', height: '100%' }}>
        <AutoSizer>
          {({ width, height }) => (
            <LineChart
              width={width}
              height={height}
              data={graphData}
              margin={{
                top: screen.md ? 25 : 15,
                right: screen.md ? 0 : -6,
                left: screen.md ? 25 : 0,
                bottom: screen.md ? 5 : 15,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" fill="#eee" />
              <XAxis
                dataKey="timestamp"
                type="number"
                interval="preserveStartEnd"
                domain={[graphData[graphData.length - 1]?.timestamp, graphData[0]?.timestamp]}
                tickFormatter={(epoch) => dayjs.unix(epoch).format('H:mm')}
                ticks={xTicks}
                tickMargin={12}
                minTickGap={1}
                tickCount={xTicks.length}
              />
              <YAxis
                dataKey="avg"
                type="number"
                orientation="right"
                interval="equidistantPreserveStart"
                label={{ value: 'kph', position: 'insideRight' }}
                domain={[0, highestGust ? highestGust + 5 : 40]}
                tickFormatter={(kph) => round(kph)}
                ticks={yTicks}
                minTickGap={1}
                tickCount={yTicks.length}
              />
              <Tooltip
                content={({ payload }) => {
                  const dir = (payload?.length && payload[0].payload?.dir) || 0;
                  const timestamp = (payload?.length && payload[0].payload?.timestamp) || 0;
                  const lull = (payload?.length && round(payload[0].payload?.lull, 1)) || '-';
                  const avg = (payload?.length && round(payload[0].payload?.avg, 1)) || '-';
                  const gust = (payload?.length && round(payload[0].payload?.gust, 1)) || '-';
                  return (
                    <div className="table-tooltip">
                      <CgArrowLongDown style={{ transform: `rotate(${dir}deg) scale(1.5)`, marginBottom: '0.5em' }} />
                      <span>{dayjs.unix(timestamp).format('h:mma')}</span>
                      <div className="table-tooltip-row">
                        <span className="table-tooltip-label">Gust:</span>
                        <span>{gust} kph</span>
                      </div>
                      <div className="table-tooltip-row">
                        <span className="table-tooltip-label">Avg:</span>
                        <span>{avg} kph</span>
                      </div>
                      <div className="table-tooltip-row">
                        <span className="table-tooltip-label">Lull:</span>
                        <span>{lull} kph</span>
                      </div>
                    </div>
                  );
                }}
              />
              <Line
                type="monotone"
                dataKey="gust"
                stroke="#f26363"
                dot={false}
                activeDot={{ r: 8 }}
                // label={({ x, y, index }) => {
                //   const timestamp = graphData[index]?.timestamp;
                //   const dir = graphData[index]?.dir;
                //   const min = dayjs.unix(timestamp).minute();
                //   return (
                //     (min < 2 ||
                //       (min - 15 < 2 && min - 15 >= 0) ||
                //       (min - 30 < 2 && min - 30 >= 0) ||
                //       (min - 45 < 2 && min - 45 >= 0)) && (
                //       <text
                //         x={x}
                //         y={40}
                //         // dx={0}
                //         // dy={-16}
                //         rotate={dir}
                //         // transform={`translate(${0}, ${0}) rotate(${dir}) translate(-${0}, -${0})`}
                //         // transform={`translate(${x}, ${y}) rotate(${0}) translate(-${x}, -${y})`}
                //         fontSize={20}
                //         textAnchor="middle"
                //         fill="darkslategrey"
                //       >
                //         ↓
                //       </text>
                //     )
                //   );
                // }}
              />
              <Line
                type="monotone"
                dataKey="avg"
                stroke="#d28323"
                strokeDasharray="2"
                dot={false}
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="lull" stroke="#43a243" dot={false} activeDot={{ r: 8 }} />
            </LineChart>
          )}
        </AutoSizer>
      </div>
    </div>
  );

  const tempestTable = (
    <div className="tempest-table-wrapper">
      {!screen.md && stationSwitch}
      <Table
        className="tempest-table"
        columns={tableColumns}
        dataSource={tableData}
        scroll={tableShowMore && { y: true }}
        pagination={false}
        size="small"
      />
      {/* <Button type="link" onClick={() => setTableShowMore((value) => !value)}>
        Show {tableShowMore ? 'less' : 'more'}
      </Button> */}
      {screen.md && stationSwitch}
    </div>
  );

  const windy = (
    <iframe
      src="https://embed.windy.com/embed.html?type=map&location=coordinates&metricRain=mm&metricTemp=°C&metricWind=km/h&zoom=9&overlay=wind&product=ecmwf&level=surface&lat=50.985&lon=-114.517&detailLat=51.18&detailLon=-114.47&detail=true"
      frameborder="0"
    ></iframe>
  );

  const rasp = (
    <iframe src="https://canadarasp.com/windgrams/index.htm?region=10&location=1&plotType=0" frameborder="0"></iframe>
  );

  const withContainer = (children, extraClassName) => {
    let className = 'container';
    if (extraClassName) className += ' ' + extraClassName;
    if (!screen.md) className += ' mobile';
    return <div className={className}>{children}</div>;
  };

  const flyable = getFlyableDesc();

  return (
    <div className="weather">
      <h1 className="page-title">Current Conditions</h1>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          padding: '0.5em',
          backgroundColor: flyable[2],
          color: flyable[3],
          transition: 'all 0.35s',
        }}
      >
        <b>Flyable right now?</b> {flyable[1]}
      </div>
      <div className="content">
        {screen.md ? (
          <>
            {withContainer(tempestTable)}
            {withContainer(camera)}
            {withContainer(tempestChart, 'no-pad')}
            {withContainer(windy)}
            {withContainer(rasp, 'wide tall')}
          </>
        ) : (
          <>
            <Menu
              mode="horizontal"
              style={{ width: '100%', justifyContent: 'center' }}
              selectedKeys={[menuTab]}
              onClick={(e) => setMenuTab(e.key)}
              items={[
                { key: '0', icon: <FaCamera style={{ fontSize: '1.75em' }} /> },
                { key: '1', icon: <GiWindsock style={{ fontSize: '1.75em' }} /> },
                { key: '2', icon: <VscGraphLine style={{ fontSize: '1.75em' }} /> },
                { key: '3', icon: <TbBrandWindy style={{ fontSize: '1.75em' }} /> },
                { key: '4', icon: <FaCanadianMapleLeaf style={{ fontSize: '1.75em' }} /> },
              ]}
            />
            {withContainer(camera, menuTab !== '0' && 'hidden')}
            {withContainer(tempestTable, menuTab !== '1' && 'hidden')}
            {withContainer(tempestChart, menuTab !== '2' ? 'hidden' : 'no-pad')}
            {menuTab === '3' && withContainer(windy)}
            {menuTab === '4' && withContainer(rasp, 'wide tall')}
          </>
        )}
      </div>
    </div>
  );
}

export default Weather;
